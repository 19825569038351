import React from 'react';

// import mui components
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const MudahCopyright = () => {
  return (
    <>
      <Typography
        color="text.secondary"
        align="center"
        sx={{ mt: 1, fontSize: { xs: 8, md: 12 } }}
      >
        {'Copyright © '}
        {new Date().getFullYear()}{' '}
        <Link
          color="inherit"
          href="https://mudahindonesia.com/"
          target="_blank"
        >
          PT Hidup Mudah Indonesia
        </Link>
        {' — '}
        <Link
          color="inherit"
          href="https://mudahindonesia.com/"
          target="_blank"
        >
          Syarat dan Ketentuan
        </Link>
      </Typography>
    </>
  );
};

export default MudahCopyright;
