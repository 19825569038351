import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import mui components
import { Button, Box, CircularProgress, Grid, Typography } from '@mui/material';

// import page components
import AuthBox from '../components/auth/AuthBox';
import MudahLogoPair from '../components/MudahLogoPair';
import InputPin from '../components/InputPin';

// import static assets
import XenditLogo from '../../assets/xendit-logo.png';

// import auth context
import { useUserAuth } from '../../context/UserAuthContext';

// import helper functions
import { cyrb53 } from '../../helper';

const ResetPin = () => {
  const [pinValid, setPinValid] = useState(false);
  const [pinValidB, setPinValidB] = useState(false);
  const [pinNumber, setPinNumber] = useState('');
  const [pinNumberB, setPinNumberB] = useState('');
  const [loading, setLoading] = useState(false);

  const { updateUserProfileFunc, refreshUserData } = useUserAuth();

  const navigate = useNavigate();

  const pinReg = new RegExp('^[0-9]{6}$');

  const pinChangeHandler = (inputNumber) => {
    setPinNumber(inputNumber);
    pinReg.test(pinNumber) ? setPinValid(true) : setPinValid(false);
  };

  const pinChangeHandlerB = (inputNumber) => {
    setPinNumberB(inputNumber);
    pinReg.test(pinNumberB) ? setPinValidB(true) : setPinValidB(false);
  };

  const formHandler = async (e) => {
    e.preventDefault();

    setLoading(true);

    let hashedPin = cyrb53(pinNumber);
    try {
      if (pinNumber === pinNumberB) {
        await updateUserProfileFunc({ pinNumber: hashedPin });
        await refreshUserData();

        setLoading(false);
        alert('PIN berhasil diganti');
        navigate('/');
      } else {
        setLoading(false);
        alert('Pastikan kedua PIN yang Anda masukkan sama');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthBox>
      <MudahLogoPair />
      <Typography
        component="h1"
        variant="h4"
        align="left"
        sx={{
          mt: 2,
          fontWeight: '600',
        }}
      >
        Reset Nomor PIN
      </Typography>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-end"
        sx={{
          height: '60vh',
        }}
      >
        <Grid item container justifyContent="center" alignItems="center">
          <Box
            component="form"
            onSubmit={formHandler}
            sx={{ width: 1, height: 1 }}
          >
            <Grid container justifyContent="center">
              <Grid
                item
                container
                justifyContent="align-left"
                xs={12}
                sx={{ mt: 4 }}
              >
                <Typography>Masukkan PIN Baru</Typography>
              </Grid>

              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                sx={{ mt: 2 }}
              >
                <InputPin pinChangeHandler={pinChangeHandler} />
              </Grid>

              <Grid
                item
                container
                justifyContent="align-left"
                xs={12}
                sx={{ mt: 4 }}
              >
                <Typography>Konfirmasi PIN Baru</Typography>
              </Grid>

              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                sx={{ mt: 2 }}
              >
                <InputPin pinChangeHandler={pinChangeHandlerB} />
              </Grid>

              <Grid
                item
                container
                justifyContent="center"
                xs={12}
                sx={{ mt: 4 }}
              >
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  sx={{ height: '50px', borderRadius: '25px' }}
                  disabled={!pinValid || !pinValidB || loading}
                >
                  {!loading ? (
                    'Ganti PIN'
                  ) : (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: 'primary.mudahWhite',
                        position: 'absolute',
                      }}
                    />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ mt: 'auto', width: 1 }}
        >
          <Grid item>
            <Typography variant="body2">Bekerja sama dengan: </Typography>
          </Grid>
          <Grid item>
            <img src={XenditLogo} alt="Xendit Logo" />
          </Grid>
        </Grid> */}
      </Grid>
    </AuthBox>
  );
};

export default ResetPin;
